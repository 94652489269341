<template>
  <div class="cell-template">
    <span class="text-muted">{{ predictiveMargin | formatNumber }}</span>
    <span
      :class="[ 'roas', predictiveMargin > 0 ? '' : 'down' ]"
    >
      {{ predictiveRoas }} {{ predictiveRoas ? '%' : '' }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'CellRenderer',
  data() {
    return {
      predictiveRoas: null,
      predictiveMargin: null,
    };
  },
  beforeMount() {
    if (!this.params.value) {
      return;
    }
    if (this.params.value[`roas${this.params.ltvDistance}`]) {
      this.predictiveRoas = Math.round(this.params.value[`roas${this.params.ltvDistance}`] * 100);
      this.predictiveMargin = this.params.value[`margin${this.params.ltvDistance}`];
    }
  },
};
</script>

<style scoped lang="scss">
.cell-template {
  display: flex;
  justify-content: space-between;

  .roas {
    margin-left: 10px;
    width: 40px;
    text-align: right;

    &.down {
      color: red;
    }
  }
}
</style>
