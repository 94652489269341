<template>
  <div class="spent">
    <div class="title-section">
      <h1 class="title">
        Predictive ROAS
        <span class="text-muted">(spent Monetization)</span>
      </h1>
      <div class="pred-roas-filter">
        <div class="ltv-duration">
          <b-field
            label="Predictive ROAS"
            label-position="on-border"
          >
            <b-radio-button
              v-model="ltvDistance"
              native-value="1y"
              type="is-primary is-light is-outlined"
            >
              1 Year
            </b-radio-button>

            <b-radio-button
              v-model="ltvDistance"
              native-value="2y"
              type="is-primary is-light is-outlined"
            >
              2 Years
            </b-radio-button>

            <b-radio-button
              v-model="ltvDistance"
              native-value="3y"
              type="is-primary is-light is-outlined"
            >
              3 Years
            </b-radio-button>
          </b-field>
        </div>
        <export :grid-api="api || undefined" />
      </div>
    </div>

    <filters :variant-options="variantOptions" />

    <section>
      <grid
        :columns="columns"
        :data="roas"
        :loading="$apollo.loading"
        :show-footer="false"
        :auto-group-column="autoGroupColumnDef"
        @ready="api = $event"
      />
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import countries from '../data/countries';
import CellRenderer from '../components/predictiveRoas/CellRenderer';
import { GET_PREDICTIVE_ROAS } from '../gql-requests';
import GridMixin from '../mixins/GridMixin';
import Export from '../components/predictiveRoas/Export';

export default {
  components: {
    Export,
    // eslint-disable-next-line
    CellRenderer,
  },
  mixins: [GridMixin],
  data() {
    return {
      variantOptions: [
        {
          key: 'country',
          name: 'Country',
        },
        {
          key: 'lunigroup',
          name: 'Luni Group',
        },
      ],
      appNames: {},
      predictiveRoasByApp: [],
      predictiveRoasObject: {},
      countries,
      api: null,
      ltvDistance: '3y',
    };
  },
  computed: {
    ...mapGetters('filters', ['getDateRange', 'getGranularity', 'getVariant']),
    autoGroupColumnDef() {
      return {
        headerName: `App > ${this.getVariant && this.getVariant[0].toUpperCase() + this.getVariant.slice(1)}`,
        minWidth: 300,
        field: 'country',
        pinned: 'left',
      };
    },
    isVariantCountry() {
      return this.getVariant === 'country';
    },
    isVariantLuniGroup() {
      return this.getVariant === 'lunigroup';
    },
    timePeriods() {
      const timePeriods = [];
      const timePeriod = moment(this.getDateRange[0]);
      const end = moment(this.getDateRange[1]).endOf('month');
      const fieldFormat = this.getGranularity === 'weekly' ? 'YYYYWW' : 'YYYYMM';
      const labelFormat = this.getGranularity === 'weekly' ? 'DD MMM YYYY' : 'MMMM YYYY';
      while (timePeriod.isSameOrBefore(end)) {
        timePeriods.push({
          field: timePeriod.format(fieldFormat),
          label: timePeriod.format(labelFormat),
        });
        const period = this.getGranularity === 'weekly' ? 'week' : 'month';
        timePeriod.add(1, period);
      }
      return timePeriods;
    },
    columns() {
      const columns = [
        {
          field: 'appName',
          colId: 0,
          headerName: 'App',
          rowGroup: true,
          hide: true,
        },
      ];

      columns.push({
        field: 'country',
        colId: 1,
        headerName: 'Country',
        filter: true,
        sortable: true,
        pinned: 'left',
        filterParams: {
          buttons: ['reset', 'apply'],
          debounceMs: 200,
        },
        maxWidth: 150,
        menuTabs: ['generalMenuTab', 'filterMenuTab'],
        hide: true,
      });

      this.timePeriods.forEach((period) => {
        columns.push({
          field: period.field,
          colId: Number(period.field),
          headerName: period.label,
          sortable: true,
          aggFunc: this.myAvg,
          comparator: this.customComparator,
          filterParams: {
            buttons: ['reset', 'apply'],
            debounceMs: 200,
          },
          menuTabs: ['generalMenuTab'],
          cellRendererFramework: 'CellRenderer',
          cellRendererParams: {
            ltvDistance: this.ltvDistance,
          },
        });
      });

      columns.push({
        headerName: 'Total Spent',
        field: 'total',
        aggFunc: 'sum',
        sort: 'desc',
        hide: true,
      });

      return columns;
    },
    roas() {
      const roas = [];
      Object.keys(this.predictiveRoasObject).forEach((appId) => {
        Object.keys(this.predictiveRoasObject[appId]).forEach((dim) => {
          const row = {
            appName: this.appNames[appId],
          };

          row.country = dim;

          let total = 0;
          this.timePeriods.forEach((period) => {
            if (this.predictiveRoasObject[appId][dim][period.field] === undefined) {
              row[period.field] = undefined;
            }
            const present = this.predictiveRoasObject[appId][dim][period.field];
            row[period.field] = this.predictiveRoasObject[appId][dim][period.field] || null;
            total += present && present.spent ? present.spent : 0;
          });
          row.total = total;

          roas.push(row);
        });
      });

      return roas;
    },
  },
  watch: {
    predictiveRoasByApp() {
      this.formatROASAgGrid();
    },
  },
  methods: {
    withLeadingZero(number) {
      return `0${number}`.slice(-2);
    },
    customComparator(a, b) {
      const valA = a && a.roas ? Number(a.roas) : 0;
      const valB = b && b.roas ? Number(b.roas) : 0;
      if (valA === valB) return 0;
      return valA > valB ? 1 : -1;
    },
    myAvg(params) {
      let totalRevenue1y = 0;
      let totalRevenue2y = 0;
      let totalRevenue3y = 0;
      let totalSpent = 0;
      params.values.forEach((value) => {
        if (value) {
          totalRevenue1y += value.revenues1y;
          totalRevenue2y += value.revenues2y;
          totalRevenue3y += value.revenues3y;
          totalSpent += value.spent;
        }
      });
      const roas1y = (totalRevenue1y - totalSpent) / (totalSpent > 0 ? totalSpent : 1);
      const roas2y = (totalRevenue2y - totalSpent) / (totalSpent > 0 ? totalSpent : 1);
      const roas3y = (totalRevenue3y - totalSpent) / (totalSpent > 0 ? totalSpent : 1);
      return {
        roas1y,
        roas2y,
        roas3y,
        margin1y: totalRevenue1y - totalSpent,
        margin2y: totalRevenue2y - totalSpent,
        margin3y: totalRevenue3y - totalSpent,
      };
    },
    formatROASAgGrid() {
      this.predictiveRoasObject = {};

      this.predictiveRoasByApp.forEach((raw) => {
        const timePeriod = this.getGranularity === 'weekly'
          ? `${raw.year}${this.withLeadingZero(raw.weekOfYear)}`
          : `${raw.year}${this.withLeadingZero(raw.month)}`;
        if (this.predictiveRoasObject[raw.appId] === undefined) {
          this.predictiveRoasObject[raw.appId] = {};
        }

        if (this.predictiveRoasObject[raw.appId][raw.country] === undefined) {
          this.predictiveRoasObject[raw.appId][raw.country] = {};
        }
        this.predictiveRoasObject[raw.appId][raw.country][timePeriod] = {
          roas1y: raw.roas1y,
          revenues1y: raw.revenues1y,
          margin1y: raw.revenues1y - raw.spent,
          roas2y: raw.roas2y,
          revenues2y: raw.revenues2y,
          margin2y: raw.revenues2y - raw.spent,
          roas3y: raw.roas3y,
          revenues3y: raw.revenues3y,
          margin3y: raw.revenues3y - raw.spent,
          spent: raw.spent,
        };

        if (this.appNames[raw.appId] === undefined) {
          this.appNames[raw.appId] = raw.appName;
        }
      });
    },
  },
  apollo: {
    predictiveRoasByApp: {
      query: GET_PREDICTIVE_ROAS,
      skip() {
        return !this.getDateRange.length;
      },
      variables() {
        const variables = {
          // weekly: this.getGranularity === 'weekly',
          variant: this.getVariant,
          isVariantCountry: this.isVariantCountry || this.isVariantLuniGroup,
        };
        if (this.getDateRange.length) {
          const from = new Date(this.getDateRange[0]);
          const to = new Date(this.getDateRange[1]);
          variables.from = `${from.getFullYear()}-${from.getMonth() + 1}`;
          variables.to = `${to.getFullYear()}-${to.getMonth() + 1}`;
        }
        return variables;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  section {
    position: relative;
  }

  .pred-roas-filter {
    display: flex;
    align-items: center;

    & > * {
      margin-left: 10px;
    }
  }
</style>
